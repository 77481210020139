import React from 'react';
import "./about.scss";
import AboutImg from "../../assets/img/house-description-w700.jpg";

export type AboutProps = {
}

export default function About({}: AboutProps) {
    return (

        <section id="about" className="about">
            <div className="container" data-aos="fade-up">
                <div className="row">
                    <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
                        <img
                            src={AboutImg}
                            className="img-fluid"
                            alt="Salon"
                        />
                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right"
                         data-aos-delay="100">
                        <h3>Dom wakacyjny "Norda Loft" to nowy dom na wyłączność</h3>
                        <br/>
                        <p className="fst-italic">
                            Norda Loft usytuowany na zamkniętym i kameralnym osiedlu przy ul. Akacjowej 31 w
                            Dębkach, ok. 10
                            minut drogi na piechotę od szerokiej, pięknej i piaszczystej plaży.
                        </p>
                        <p>Dom wakacyjny "Norda Loft" przygotowany został dla 10 osób. Na parterze mieści się
                            salon (sofa z
                            funkcją spania, stół, krzesła, stolik kawowy, telewizor oraz kominek elektryczny),
                            aneks
                            kuchenny (zmywarka, płyta indukcyjna, ekspres do kawy firmy BOSH (pasują do niego kapsułki firmy Tassimo), lodówka z
                            chłodziarko-zamrażarka, kuchenka
                            mikrofalowa, czajnik bezprzewodowy, naczynia, sztućce, garnki) oraz łazienka (kabina
                            prysznicowa, umywalka, wc). Dla gości dostępne jest również pomieszczenie
                            gospodarcze wyposażone
                            w akcesoria plażowe, rowery i hulajnogi, a także pralko-suszarkę. Na piętrze mieszczą się 3 sypialnie i
                            toaleta (wc,
                            prysznic i umywalka). Pierwsza sypialnia z łożem małżeńskim 160/200cm, biurkiem na
                            laptopa bądź
                            toaletką oraz szafą. Druga sypialnia wyposażona została w łoże małżeńskie 140/200cm
                            i szafę. W
                            sypialni trzeciej są dwa łóżka piętrowe - dolne o wymiarach 120/200, górne 90/200,
                            szafa oraz
                            komoda z szufladami.</p>
                        <p>Ze względu na bliskość przyrody okna wyposażone są w moskitiery. </p>

                        <p>Domek wyposażony został w ogrzewanie podłogowe na parterze oraz grzejniki na piętrze.
                            Dodatkowym
                            atutem jest kominek elektryczny w salonie, który tworzy przyjemny klimat także w
                            letnie
                            wieczory, a w zimie może być również dodatkowym źródłem ciepła.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}
